import forList from "@/mixin/forList";
import ChannelContractSetEdit from "./ChannelContractSetEdit.vue";
import { levelEnum, initialPB } from "./config";


export default {
    name: "channelContractSet",
    mixins: [forList],
    components: { ChannelContractSetEdit },

    data() {
        return {
            configurationList: [],
            contractTempList: [],
            SP_: {

            },
            editingItem: null,
            setingItem: null,
        };
    },

    methods: {
        async fetchData() {
            console.log("fetching...");
            const loading = this.$loading();

            try {
                let data = { ...this.extractParams() }
                if (!data.search_sourcetype) {
                    delete data.search_sourcetype
                }

                const res = await this.$axios({
                    url: "/api/channelContractTemp/queryChannelContractTempList",
                    method: "post",
                    data: data
                });

                if (res.code === 2000) {
                    this.total = res.data.count;
                    const list = res.data.contracttempList;
                    this.tableData = list;
                    loading.close();
                } else if (res.code !== 1003)
                    throw res

            } catch (reason) {
                console.warn("获取数据失败", reason);
                this.$message({
                    message: reason.msg || "获取数据失败",
                    type: "warning"
                });

                loading.close();
            }
        },

        // 点击 "新增菜单"
        handleAddBtn() {
            this.editingItem = initialPB();
        },

        // 点击表格行操作按钮: 编辑 
        handleEdit(data) {
            this.editingItem = data;
        },
        // 点击表格行操作按钮: 删除
        async handleDelete(data) {
            let loadingRef;
            try {
                await this.$confirm("确定要删除吗", "系统提示")
                loadingRef = this.$loading();
                const res = await this.$axios({
                    url: "/api/channelContractTemp/deleteChannelContractTemp",
                    method: "post",
                    data: {
                        id: parseInt(data.id)
                    }
                })
                if (res.code === 2000) {
                    this.$message({
                        message: "删除成功",
                        type: "success"
                    });
                    loadingRef && loadingRef.close();
                    this.fetchData();

                } else if (res.code !== 1003)
                    throw res;

            } catch (reason) {
                if (reason !== "cancel") {
                    this.$message({
                        message: reason.msg || "删除失败",
                        type: "warning"
                    });
                }
                loadingRef && loadingRef.close();
            }
        },
        async getListParam() {
            const res = await this.$axios({
                url: "/api/channelContractTemp/listParam",
                method: "get",
            });
            if (res.code === 2000) {
                this.configurationList = res.data.configurationList || []
                this.contractTempList = res.data.contractTempList || []
            }
        },
    },

    created() {
        this.auth.save = this.$hasAuthFor("api/channelContractTemp/saveChannelContractTemp");
        this.auth.delete = this.$hasAuthFor("api/channelContractTemp/deleteChannelContractTemp");
        this.getListParam()
        // api/channelContractTemp/listParam
    }
};