<!-- companyContractSet  -->
<template>
    <el-dialog class="companyContractSet-edit"
        :visible="viewIsReady"
        @close="handleClose"
        :title="titleText"
        width="550px">
        <el-form class="private" 
            :model="PB"
            :rules="rules"
            label-width="130px"
            label-suffix=":"
            ref="form"
            @submit.prevent="handleSave"
        >
            <el-form-item label="渠道协议名称" prop="chagreeid">
                <el-select v-model="PB.chagreeid"
                    class="width3">
                    <el-option
                        v-for="e in configurationList"
                        :key="e.id"
                        :label="e.name"
                        :value="e.id"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="关联合同模板" prop="tempid">
                <el-select v-model="PB.tempid"
                    class="width3">
                    <el-option
                        v-for="e in contractTempList"
                        :key="e.id"
                        :label="e.title"
                        :value="e.tempCode"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="企业是否自动盖章" prop="isauto" class="flexItem">
                <el-radio v-model="PB.isauto" label="0">否</el-radio>
                <el-radio v-model="PB.isauto" label="1">是</el-radio>
            </el-form-item>
            <el-form-item label="备注" prop="remark">
                <el-input v-model="PB.remark"
                    type="textarea" rows="5"
                    class="width3" maxlength="100"></el-input>
            </el-form-item>
        </el-form>

        <div slot="footer">
            <el-button @click="handleClose">取消</el-button>
            <el-button @click="handleSave" type="primary">保存</el-button>
        </div>
    </el-dialog>
</template>

<script>
export default {
    name: 'MenuEdit',
    components: {},
    props: ["item","configurationList", "contractTempList"],

    data () {
        return {
            viewIsReady: false,
            PB: {
                chagreeid: "",
                tempid: '',
                isauto: "0",
                remark: "",
                id: '',
            },

            // 验证规则
            rules: {
                chagreeid      : { required: true, message: "请选择渠道协议名称",   trigger:['change', 'blur'] },
                tempid      : { required: true, message: "请选择关联合同模板",   trigger:['change', 'blur'] },
            },  
            parentEnum: []
        }
    },

    computed: {
        isUpdate () {
            return !!this.item?.id
        },
        titleText () {
            return this.isUpdate ? '编辑渠道商协议' : '新增渠道商协议'
        }
    },

    watch: {
        item (item, old) {
            if (item && item !== old) {
                this.PB.chagreeid = item.chagreeId||item.chagreeid
                this.PB.tempid = item.tempId|| item.tempid
                this.PB.isauto = item.isAuto||item.isauto||0
                this.PB.isauto = this.PB.isauto+''
                this.PB.remark = item.remark
                this.PB.id = item.id
                this.viewIsReady = true;

            }
        }
    },

    methods: {
        async handleSave () {
            const loadingRef = this.$loading({
                target: ".companyContractSet-edit .el-dialog"
            });

            try {
                await this.$refs.form.validate();
                const {chagreeid, tempid, isauto, remark,id} = this.PB;
                const res = await this.$axios({
                    url: "/api/channelContractTemp/saveChannelContractTemp",
                    method: "post",
                    data: {
                        chagreeid, tempid, isauto, remark,id
                    }
                })

                if (res.code === 2000) {
                    this.$message({
                        message: "保存成功",
                        type: "success"
                    });
                    loadingRef && loadingRef.close();
                    this.$emit("change");
                    this.handleClose();

                } else if (res.code !== 1003) 
                    throw res;

            } catch (reason) {
                reason && this.$message({
                    message: reason.msg || "保存失败",
                    type: "error"
                });
                console.warn(reason);
                loadingRef && loadingRef.close()
            }
        },

        handleClose () {
            this.viewIsReady = false;
            this.PB = {
                chagreeid: "",
                tempid: '',
                isauto: "0",
                remark: "",
                id: '',
            };
            this.$refs.form.clearValidate();
            this.$emit("update:item", null);
        }
    }
}
</script>

<style lang='scss' scoped>
    .flexItem{
        display: flex;
        align-items: center;

    }
    .flexItem /deep/ .el-form-item__content{
        margin-left: 0 !important;
    }
</style>